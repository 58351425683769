import { css } from 'styled-components'

// FONTS
import PantonBlack from '../assets/fonts/Panton-BlackCaps.otf'
import PantonLight from '../assets/fonts/Panton-LightCaps.otf'
import Quicksand from '../assets/fonts/Quicksand-Regular.ttf'
import QuicksandBold from '../assets/fonts/Quicksand-Bold.ttf'



export const LogoFontFace = css`
  @font-face {
    font-family: 'PantonBlack';
    font-style: normal;
    font-weight: black;
    src: url('${PantonBlack}') format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: 'PantonLight';
    font-style: normal;
    font-weight: lighter;
    src: url('${PantonLight}') format('opentype');
    font-display: swap;
  }
`


export const MainFontFace = css`
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url('${Quicksand}') format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: 'QuicksandBold';
    font-style: normal;
    font-weight: bold;
    src: url('${QuicksandBold}') format('truetype');
    font-display: swap;
  }
`