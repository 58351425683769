import React from 'react';
import styled from 'styled-components'

// Imagens
import logo from '../../assets/images/logoWhite.png';
import fb from '../../assets/images/fb.png';
import insta from '../../assets/images/insta.png';

const Footer = () => (
  <FooterBox>
    <FooterContainer>
      <SocialLink href='https://www.facebook.com/gamejamplus/' target='_BLANK' rel="noopener noreferrer">
        <SocialLogo src={fb} alt='Facebook Logo' />
        /gamejamplus
      </SocialLink>
      <Logo src={logo} alt='' />
      <SocialLink href='https://www.instagram.com/gamejamplus/' target='_BLANK' rel="noopener noreferrer">
        <SocialLogo src={insta} alt='Insta Logo' />
        @gamejamplus
      </SocialLink>
    </FooterContainer>
  </FooterBox>
);

export default Footer


const FooterBox = styled.footer`
  padding: 1rem;
  background: linear-gradient(to right, var(--purple), var(--pink));
`

const FooterContainer = styled.section`
  width: 100%;
  height: auto;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    justify-content: space-between;
  }
`

const Logo = styled.img`
  width: 4rem;

  @media screen and (max-width: 768px) {
    order: -1;
  }
`

const SocialLink = styled.a`
  font-family: var(--regular);
  font-size: 1.25rem;
  color: var(--white);
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin: 0.5rem 0;
    font-size: 1rem;
  }
`

const SocialLogo = styled.img`
  width: 2rem;
  margin-right: 0.5rem;

  @media screen and (max-width: 768px) {
    width: 1.5;
  }
`