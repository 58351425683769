const Language = (texts) => {
	let controle = undefined
	let lang = undefined

	if (typeof window !== 'undefined') {
		controle = window.localStorage.getItem('lingua');
		lang = window.navigator.language;
	}

	if (controle === undefined) {
		if (lang === 'pt-BR' || lang === 'pt') {
			return texts.PT;
		} else {
			return texts.EN;
		}
	} else {
		if (controle === 'pt') {
			return texts.PT;
		} else {
			return texts.EN;
		}
	}
}

export default Language