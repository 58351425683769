import React from "react"
import styled from "styled-components"

//Style
import GlobalStyle from "../../styles/GlobalStyles"

//Components
import Navbar from "../Navbar"
import Footer from "../Footer"

//Imagens
// import wave from '../../assets/images/wave.svg';

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Navbar />
    <Main>{children}</Main>
    <Footer />
  </>
)

export default Layout

const Main = styled.main`
  background-size: 100% auto;
  width: 100%;
`
