import brasil from "../../assets/images/brasil.png"
import usa from "../../assets/images/usa.png"

const Texts = {
  PT: {
    announcement: {
      text: "Está é uma versão antiga do site. Para acessar a nova versão",
      click: "clique aqui",
    },
    home: "Home",
    categorias: "Categorias",
    comoFunciona: "Como Funciona?",
    etapa1: "1ª Etapa",
    etapa2: "2ª Etapa",
    etapa3: "3ª Etapa",
    etapa4: "4ª Etapa",
    edicoes: "Edições",
    organizadores: "Organização",
    cursos: "Cursos",
    conquistas: "Conquistas",
    faq: "FAQ",
    videos: "Vídeos",
    linguaImg: usa,
    lingua: "en",
  },
  EN: {
    announcement: {
      text: "This is an old version of the site. To access the new version",
      click: "click here",
    },
    home: "Home",
    categorias: "Categories",
    comoFunciona: "How it works?",
    etapa1: "1st Stage",
    etapa2: "2nd Stage",
    etapa3: "3rd Stage",
    etapa4: "4th Stage",
    edicoes: "Editions",
    organizadores: "Organizers",
    cursos: "Courses",
    conquistas: "Achievements",
    faq: "FAQ",
    videos: "Videos",
    linguaImg: brasil,
    lingua: "pt",
  },
}

export default Texts
