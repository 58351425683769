import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Announcement from "../Announcement"
import Language from "../Language"
import logo from "../../assets/images/logoWhite.png"
import waveMenu from "../../assets/images/menu.svg"
import texts from "./content"

const menuMobile = () => {
  const menu = document.getElementById("menu")
  const menuVerifica = menu.classList.contains("open")
  if (menuVerifica) {
    menu.classList.remove("open")
  } else {
    menu.classList.add("open")
  }
}

const Navbar = () => (
  <Wrapper>
    <Announcement content={Language(texts).announcement} />

    <Menu id="menu">
      <NavMenu>
        <Link to="/">
          <Logo src={logo} alt="Logo GameJamPlus" />
        </Link>

        <Bars onClick={() => menuMobile()}>
          <Bar />
          <Bar />
          <Bar />
        </Bars>

        <NavLinks>
          {/* <NavLink>
          <DropdownTitle>{Language(texts).comoFunciona}</DropdownTitle>
          <Dropdown>
            <DropdownLink>
              <Link to="/etapa?1">{Language(texts).etapa1}</Link>
            </DropdownLink>
            <DropdownLink>
              <Link to="/etapa?2">{Language(texts).etapa2}</Link>
            </DropdownLink>
            <DropdownLink>
              <Link to="/etapa?3">{Language(texts).etapa3}</Link>
            </DropdownLink>
            <DropdownLink>
              <Link to="/etapa?4">{Language(texts).etapa4}</Link>
            </DropdownLink>
          </Dropdown>
        </NavLink> */}
          {/* <NavLink>
          <DropdownTitle>{Language(texts).categorias}</DropdownTitle>
          <Dropdown>
            <DropdownLink>
              <Link to="/gamejam">GameJam</Link>
            </DropdownLink>
            <DropdownLink>
              <Link to="/publishers">Publishers</Link>
            </DropdownLink>
          </Dropdown>
        </NavLink> */}
          <NavLink>
            <DropdownTitle>{Language(texts).edicoes}</DropdownTitle>
            <Dropdown>
              <DropdownLink>
                <Link to="/edicao2021">21/22</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/edicoes?2020">20/21</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/edicoes?AQGJ+">AQGJ+</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/edicoes?2019">2019</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/edicoes?2018">2018</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/edicoes?2017">2017</Link>
              </DropdownLink>
            </Dropdown>
          </NavLink>
          <NavLink>
            <DropdownTitle>{Language(texts).organizadores}</DropdownTitle>
            <Dropdown>
              <DropdownLink>
                <Link to="/organizadores?2021">21/22</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/organizadores?2020">20/21</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/organizadores?AQGJ+">AQGJ+</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/organizadores?2019">2019</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/organizadores?2018">2018</Link>
              </DropdownLink>
              <DropdownLink>
                <Link to="/organizadores?2017">2017</Link>
              </DropdownLink>
            </Dropdown>
          </NavLink>
          {/* <NavLink>
          <Link to="/cursos">{Language(texts).cursos}</Link>
        </NavLink> */}
          <NavLink>
            <Link to="/conquistas">{Language(texts).conquistas}</Link>
          </NavLink>
          <NavLink>
            <Link to="/faq">{Language(texts).faq}</Link>
          </NavLink>
          <NavLink>
            <Link to="/videos">{Language(texts).videos}</Link>
          </NavLink>
          <NavLink
            onClick={function (e) {
              const lingua = Language(texts).lingua
              if (typeof window !== "undefined")
                window.localStorage.setItem("lingua", lingua)
              if (typeof window !== "undefined") window.location.reload()
            }}
          >
            <LanguageFlag src={Language(texts).linguaImg} alt="" />
          </NavLink>
        </NavLinks>
      </NavMenu>
    </Menu>
  </Wrapper>
)

export default Navbar

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 99;
`

const Menu = styled.div`
  width: 100%;
  background: var(--gradient);
  padding: 1rem 0 0;

  &::after {
    content: "";
    height: 6vw;
    width: 100%;
    background: red;
    background: url(${waveMenu}) center center no-repeat;
    background-size: 100% auto;
    position: absolute;
    left: 0;
    bottom: -5.9vw;
  }
  @media screen and (max-width: 1200px) {
    padding: 1rem 0;
  }
  &.open {
    div {
      div:nth-child(1) {
        top: 26px;
        transform: rotate(45deg);
      }
      div:nth-child(2) {
        left: 15px;
        opacity: 0;
      }
      div:nth-child(3) {
        top: 26px;
        transform: rotate(-45deg);
      }
    }
    ul {
      height: auto;
    }
  }
`

const NavMenu = styled.nav`
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.img`
  width: 80px;
  @media screen and (max-width: 1200px) {
    width: 64px;
  }
`

const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  font-family: var(--primary);
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 0;
    background: var(--gradient);
    padding: 0;
    display: block;
    position: absolute;
    top: 98px;
    left: 0;
    transition: height 0.5s, padding 0.5s;
    overflow: hidden;
    z-index: 99999;
  }
`

const NavLink = styled.li`
  height: 2rem;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  a {
    font-size: 1rem;
    color: #fff;
    text-align: center;
  }
  &:hover {
    ul {
      display: flex;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
    margin: 0;
    display: flex;
    padding: 12px 0;
    flex-direction: column;
    justify-content: center;
    float: none;
  }
`
const DropdownTitle = styled.span`
  font-size: 1rem;
  color: #fff;
`

const Dropdown = styled.ul`
  min-width: 100%;
  flex-direction: column;
  display: none;
  position: absolute;
  top: 2rem;
  z-index: 99999;
  @media screen and (max-width: 1200px) {
    position: static;
    overflow: hidden;
    height: 0;
    transition: all 0.5s;
  }
`
const DropdownLink = styled.li`
  min-width: 100%;
  height: 2rem;
  padding: 0.5rem;
  border: solid 1px var(--white);
  background: var(--gradient);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  a {
    font-size: 1rem;
    color: #fff;
    text-align: center;
  }
  @media screen and (max-width: 1200px) {
    padding: 1rem 0;
  }
`

const LanguageFlag = styled.img`
  width: 32px;
`

const Bars = styled.div`
  width: 64px;
  height: 64px;
  border: 1.5px solid var(--white);
  border-radius: 50%;
  display: none;
  position: relative;
  div:nth-child(1) {
    top: 12px;
  }
  div:nth-child(2) {
    top: 27px;
  }
  div:nth-child(3) {
    top: 42px;
  }
  @media screen and (max-width: 1200px) {
    display: block;
  }
`

const Bar = styled.div`
  width: 32px;
  height: 2px;
  margin: 3px 0;
  background: var(--white);
  border-radius: 6px;
  position: absolute;
  left: calc(50% - 16px);
  transition: all 0.3s;
`
