import React from "react"
import styled from "styled-components"

const Announcement = ({ content }) => (
  <Wrapper>
    <Text>
      {content.text + " "}
      <a href="https://gamejamplus.com">{content.click}</a>.
    </Text>
  </Wrapper>
)

export default Announcement

const Wrapper = styled.div`
  width: 100%;
  padding: 8px 32px;
  background-color: var(--pink);
`

const Text = styled.p`
  width: 100%;
  font-family: var(--bold);
  text-align: center;
  color: var(--white);

  a {
    color: var(--blue);
  }
`
