
  
import { createGlobalStyle } from 'styled-components'
import { LogoFontFace, MainFontFace } from "./Fonts";

import waves from '../assets/images/wave.svg';

const GlobalStyle = createGlobalStyle`
  ${MainFontFace}
  ${LogoFontFace}
  :root {
    --primary: #58808A;
    --white: #FFF;
    --black: #000;
    --blue: #001E3C;
    --green: #58808A;
    --purple: #3C3958;
    --pink: #834769;
    --lightPink: #db686d;
    --gradient: linear-gradient(to right, var(--blue), var(--green));
    --vertGradient: linear-gradient(to bottom, var(--blue), var(--green));
    --primary: 'PantonBlack';
    --primaryLight: 'PantonLight';
    --bold: 'QuicksandBold';
    --regular: 'Quicksand';
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    background: var(--white);
    line-height: 1;
    font-family: var(---regular);
    font-style: normal;
    font-weight: 400;
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
  }
  
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  *{
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    &:hover, &:active, &:focus {
      outline-style: none;
    }
  }
  html{
    &::-webkit-scrollbar-track {
      background: #001E3C;
    }
    &::-webkit-scrollbar {
      width: 8px;
      // background: #F4F4F4;
    }
    &::-webkit-scrollbar-thumb {
      background: #58808A;
    }
  }
  main {
    padding-top: 8vw;
    background: url(${waves}) center top / 100% no-repeat;
  }
  h1, h2, h3, h4, h5 {
    font-family: var(---primary);
    font-style: normal;
    font-weight: black;
  }
  .ReactModal__Overlay {
    z-index: 999;
  }
  .i4ewOd-pzNkMb-haAclf{
    display: none;
  }
`

export default GlobalStyle